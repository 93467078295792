const SectionTestimonials = () => {
    return (
        <section>
            <div className="container p-4 p-lg-5">
                <div className="text-center mb-5">
                    <h2 className="text-primary fw-bold">Apa Kata Mereka?</h2>
                    <p>Beberapa testimoni para customer yang puas dengan pelayanan kami</p>
                </div>

                <div className="row g-4">
                    <div className="col-md-6 col-lg-3">
                        <img className="img-fluid rounded-4 shadow-lg" style={{ objectFit: 'contain' }} src="https://i.ibb.co/3pHxFzj/Whats-App-Image-2024-03-08-at-10-25-02-b27e69bb.jpg" alt="Testi 1" />
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <img className="img-fluid rounded-4 shadow-lg" style={{ objectFit: 'contain' }} src="https://i.ibb.co/Gd1jd5T/Whats-App-Image-2024-03-08-at-10-26-05-2480cc9d.jpg" alt="Testi 2" />
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <img className="img-fluid rounded-4 shadow-lg" style={{ objectFit: 'contain' }} src="https://i.ibb.co/Dw2nnMw/Whats-App-Image-2024-03-08-at-10-26-04-d31ddd7f.jpg" alt="Testi 3" />
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <img className="img-fluid rounded-4 shadow-lg" style={{ objectFit: 'contain' }} src="https://i.ibb.co/CKS4Xyf/Whats-App-Image-2024-03-08-at-10-26-05-fe98b44a.jpg" alt="Testi 4" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionTestimonials