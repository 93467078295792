import { Helmet } from 'react-helmet-async';
import Layout from '../../components/global/Layout/Layout';
import Hero from '../../components/home/Hero/Hero';
import SectionFaq from '../../components/home/SectionFaq/SectionFaq';
import SectionIntro from '../../components/home/SectionIntro/SectionIntro';
import SectionAbout from '../../components/home/SectionAbout/SectionAbout';
import SectionContact from '../../components/home/SectionContact/SectionContact';
import SectionCTA from '../../components/home/SectionCTA/SectionCTA';
import SectionTestimonials from '../../components/home/SectionTestimonials/SectionTestimonials';
import SectionReviews from '../../components/home/SectionReviews/SectionReviews';
import SectionFeaturedProducts from '../../components/home/SectionFaturedProducts/SectionFeaturedProducts';
import SectionMap from '../../components/home/SectionMap/SectionMap';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Buat Tas Custom Desain Suka-suka | JagoKemasan.com</title>
            </Helmet>
            <Layout>
                <Hero />
                <SectionIntro />
                <SectionAbout />
                <SectionMap />
                <SectionFeaturedProducts />
                <SectionReviews />
                <SectionTestimonials />
                <SectionCTA />
                <SectionFaq />
                <SectionContact />
            </Layout>
        </>
    );
};

export default Home;
