const SectionCTA = () => {
    return (
        <section id="contact">
            <div className="container p-4 p-lg-5">
                <a className="link" title="Pesan Sekarang" href="https://wa.me/62895402932828?text=Halo, saya ingin order dengan kode promo JAGOBERKAH, tolong dibantu." target="_blank" rel="noopener noreferrer">
                    <img className="img-fluid" src="https://i.ibb.co/tJc0YYG/image.png" alt="CTA" />
                </a>
            </div>
        </section>
    )
}

export default SectionCTA