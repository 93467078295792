const SectionReviews = () => {
    return (
        <section className="bg-primary text-white">
            <div className="container p-4 p-lg-5">
                <div className="text-center mb-5">
                    <h2 className="fw-bold">Review Produk Dari Para Customer</h2>
                </div>
                <div className="row justify-content-center g-5">
                    <div className="col-md-6 col-lg-4">
                        <div className="ratio ratio-16x9">
                            <iframe className="rounded-5" width="560" height="315" src="https://www.youtube.com/embed/AEfLFt3pZAQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="ratio ratio-16x9">
                            <iframe className="rounded-5" width="560" height="315" src="https://www.youtube.com/embed/nAqD3fBWNlc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="ratio ratio-16x9">
                            <iframe className="rounded-5" width="560" height="315" src="https://www.youtube.com/embed/DE8Sz1nIDPM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="ratio ratio-16x9">
                            <iframe className="rounded-5" width="560" height="315" src="https://www.youtube.com/embed/6tAD-iX41i0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="ratio ratio-16x9">
                            <iframe className="rounded-5" width="560" height="315" src="https://www.youtube.com/embed/xi24n10KCyg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionReviews