const SectionIntro = () => {
    return (
        <section className="bg-primary text-white py-5">
            <div className="container py-5 px-4 px-lg-5">
                <div className="row justify-content-between">
                    <div className="col-lg-6 my-auto mb-4 mb-lg-auto">
                        <img className="img-fluid" src="https://i.ibb.co/D9Ycwvf/AP2-TBATIK2-scaled-1.png" loading="lazy" alt="Intro" />
                    </div>
                    <div className="col-lg-6 my-auto">
                        <ul className="lh-lg">
                            <li>Butuh tas custom untuk mempercantik produkmu?</li>
                            <li>Butuh tas custom dengan desain sesuka kamu?</li>
                            <li>Butuh tas custom dengan bahan kualitas terbaik?</li>
                            <li>Butuh tas custom dengan warna yang semaumu?</li>
                            <li>Butuh tas custom dengan jumlah banyak dan selesai dengan waktu cepat?</li>
                            <li>Butuh tas custom untuk parcelmu dengan kualitas terbaik dan bergaransi?</li>
                        </ul>
                        <p>
                            Semua masalah kamu adalah hal yang biasa kami tangani,kami adalah mitra tepercaya dengan ribuan brand telah kami kerjakan.
                        </p>
                        <p className="h4 fw-bolder">
                            JagoKemasan adalah solusinya! Tunggu apalagi buruan order sekarang juga!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionIntro;
