const SectionFaq = () => {
    const faqs = [
        {
            id: 1,
            question: 'Apa itu JagoKemasan?',
            answer: 'JagoKemasan adalah produsen (pabrik) langsung pembuatan tas custom untuk coporate dan UMKM, kami telah 27 tahun beridiri. Ribuan mitra telah memercayai kami dan ribuan produk terjual dengan rate memuaskan. Lokasi kantor kami di Jl. Ring Road Utara No.88, Trini, Trihanggo, Kec. Gamping, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55291'
        },
        {
            id: 2,
            question: 'Berapa lama pembuatan kemasannya?',
            answer: 'Proses pembuatan biasanya memakan waktu 1 sampai 10 hari kerja, namun ini tergantung dari berapa banyak jumlah yang dipesan'
        },
        {
            id: 3,
            question: 'Apakah bisa custom desain?',
            answer: 'Bebas custom desain sesukamu!'
        },
        {
            id: 4,
            question: 'Bisa bikin kemasan bagaimana saja?',
            answer: 'Kami bisa membuat kemasan apa saja, mulai dari paper bag, tas kain, dan masih banyak lagi.'
        },
        {
            id: 5,
            question: 'Apa kelebihan JagoKemasan dibanding yang lain?',
            answer: 'Pengalaman kami yang telah 27 tahun dalam menangani klien tidak diragukan lagi, harga yang kami berikan adalah harga produsen (pabrik) langsung, kualitas terjamin dan bergaransi juga dengan harga yang sangat amat terjangkau!'
        },
        {
            id: 6,
            question: 'Apakah ada garansi untuk produk yang dipesan?',
            answer: 'Ada garansi dari kami, apabila pesanan tidak sesuai dengan yang di pesan di awal silahkan ajukan penggantian barang sesuai prosedur yang berlaku'
        },
    ];

    return (
        <section id="faq">
            <div className="container py-5 px-4 px-lg-5">
                <div className="text-center my-5">
                    <h2 className="fw-bold">FAQ</h2>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="accordion accordion-flush" id="customAccordion">
                            {faqs.map((faq) => {
                                return (
                                    <div className="accordion-item border-0 mb-1" key={faq.id}>
                                        <h2 className="accordion-header" id={`flush-heading${faq.id}`}>
                                            <button className={`accordion-button ${faq.id !== 1 ? 'collapsed' : ''} shadow-none fw-bolder`} type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${faq.id}`} aria-expanded={faq.id === 1 ? "true" : "false"} aria-controls={`flush-collapse${faq.id}`}>
                                                {faq.question}
                                            </button>
                                        </h2>
                                        <div id={`flush-collapse${faq.id}`} className={`accordion-collapse collapse ${faq.id === 1 ? 'show' : ''}`} aria-labelledby={`flush-heading${faq.id}`} data-bs-parent="#customAccordion">
                                            <div className="accordion-body">
                                                <p className="text-muted lh-lg">{faq.answer}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionFaq;
