import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Hero = () => {
    return (
        <section className="bg-light pt-5">
            <div className="container py-5 px-4 px-lg-5">
                <div className="row justify-content-between">
                    <div className="col-xl-6 my-auto">
                        <h1 className="display-3 fw-bold mb-4">Buat Tas Custom, <span className="text-primary">Desain Suka-suka!</span></h1>
                        <p className="text-muted lh-lg mb-4">Banyak pilihan desain tas custom yang sangat menarik untuk kebutuhan produkmu, kamu bisa juga buat sendiri desain packaging sesuai selera kamu. <span className="text-primary">Apalagi ada promo ramadhan pasti tambah untung</span>.</p>
                        <div className="d-grid gap-3 d-xl-flex  my-4">
                            <a className="btn btn-warning text-primary fw-bolder border border-primary border-3 rounded-1 px-3 py-2" href="https://wa.me/62895402932828?text=Halo, saya ingin order dengan kode promo JAGOBERKAH, tolong dibantu.">
                                <FontAwesomeIcon className="me-2" icon={faCartArrowDown} />
                                Pesan Sekarang
                            </a>
                            <a className="btn btn-outline-primary border-primary border border-3 rounded-1 px-3 py-2" href="https://wa.me/62895402932828?text=Halo, saya ingin order dengan kode promo JAGOBERKAH, tolong dibantu." target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="me-2" icon={faWhatsapp} />
                                Tanya CS
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-6 my-auto p-0">
                        <img className="img-fluid d-block mx-auto" src="https://i.ibb.co/c1PYqqy/Banner-4.png" alt="Hero" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
