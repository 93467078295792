const SectionFeaturedProducts = () => {
    return (
        <section>
            <div className="container p-4 p-lg-5">
                <h2 className="text-primary text-center fw-bold">Produk Unggulan</h2>

                <div className="row g-3 mt-4">
                    <div className="col-md-6 col-lg-3 text-center">
                        <img className="img-fluid" src="https://i.ibb.co/JzWwYft/image.png" alt="Tas Furing Custom" />
                        <p className="fw-bold h4 my-3">Tas Furing Custom</p>
                    </div>
                    <div className="col-md-6 col-lg-3 text-center">
                        <img className="img-fluid" src="https://i.ibb.co/vX6XNRb/image.png" alt="Tas Kertas Custom" />
                        <p className="fw-bold h4 my-3">Tas Kertas Custom</p>
                    </div>
                    <div className="col-md-6 col-lg-3 text-center">
                        <img className="img-fluid" src="https://i.ibb.co/grSFknD/image.png" alt="Tas Kain Custom" />
                        <p className="fw-bold h4 my-3">Tas Kain Custom</p>
                    </div>
                    <div className="col-md-6 col-lg-3 text-center">
                        <img className="img-fluid" src="https://i.ibb.co/T87wdmg/image.png" alt="Tas Polos Custom" />
                        <p className="fw-bold h4 my-3">Tas Polos Custom</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionFeaturedProducts