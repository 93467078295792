import { faHeadset } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SectionContact = () => {
    return (
        <section className="bg-warning">
            <div className="container p-4 p-lg-5">
                <h2 className="text-primary fw-bold text-center">Tertarik Namun Masih Bingung?</h2>
                <p className="text-center my-4">Tenang saja dan jangan ragu CS kami siap membantu kamu dengan layanan konsultasi gratis selama jam kerja</p>
                <div className="text-center">
                    <a className="btn btn-outline-primary btn-lg rounded-3" href="https://wa.me/62895402932828?text=Halo, saya ingin order dengan kode promo JAGOBERKAH, tolong dibantu." target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faHeadset} /> Tanya CS Kami
                    </a>
                </div>
            </div>
        </section>
    )
}

export default SectionContact