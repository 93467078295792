const SectionMap = () => {
    return (
        <section style={{ background: '#87B6BB', color: '#094348' }}>
            <div className="container p-4 p-lg-5">
                <h2 className="fw-bold text-center">Kami Telah Dipercaya Ribuan Brand dan Telah <span className="text-danger">27 Tahun</span> Bertumbuh Bersama, Serta Telah Tersebar ke <span className="text-danger">Seluruh Indonesia</span></h2>

                <img className="img-fluid" src="https://i.ibb.co/rQWxsnf/Screenshot-2024-03-10-174310.png" alt="Map" />
            </div>
        </section>
    )
}

export default SectionMap