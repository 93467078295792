import { faClover, faPalette, faPaperPlane, faShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionAbout = () => {
    return (
        <>
            <section>
                <div className="container py-5 px-4 px-lg-5">
                    <div className="row my-5 py-5">
                        <div className="col-lg-6 my-auto mb-4 mb-lg-auto">
                            <h2 className="fw-bolder mb-3 text-primary">Tentang JagoKemasan</h2>
                            <p className="lh-lg">
                                Kami Merupakan produsen (paperbag), aneka box, bahan baku box, tali kertas dan goodybag yang berdiri sejak tahun 1997 di Yogyakarta sebagai pelopor penggunaan Kertas kemasan penganti plastik. Tas kertas dipakai untuk mengemas barang jualan,media untuk barang yang dibeli konsumen seperti batik, fashion, parfume, tshirt, kue, dll. serta untuk kebutuhan souvenir dan hajatan lain. Kami telah dipercaya 1000+ brand untuk menjadi mitra kami dengan 12000+ produk terjual. dengan pengalaman 27 tahun memproduksi untuk kebutuhan mitra kami.
                            </p>
                        </div>
                        <div className="col-lg-6 my-auto order-first order-lg-last mb-5 mb-lg-auto">
                            <img className="img-fluid" src="https://i.ibb.co/2Pz6pq3/DSCF8379-1.png" loading="lazy" alt="About 1" />
                        </div>
                    </div>
                    <div className="row my-5 py-5">
                        <div className="col-lg-6 my-auto mb-4 mb-lg-auto">
                            <img className="img-fluid px-lg-5" src="https://i.ibb.co/CMpw4NS/furingidul-1.png" loading="lazy" alt="About 2" />
                        </div>
                        <div className="col-lg-6 my-auto">
                            <h2 className="fw-bolder mb-4 text-primary">Kenapa Pilih Kami?</h2>

                            <div className="d-flex gap-3 mb-3">
                                <FontAwesomeIcon size="3x" className="text-primary" icon={faPalette} />
                                <p>Banyak pilihan <span className="text-primary">desain yang menarik</span>, dan dapat disesuaikan dengan kebutuhan.</p>
                            </div>
                            <div className="d-flex gap-3 mb-3">
                                <FontAwesomeIcon size="3x" className="text-primary" icon={faShield} />
                                <p><span className="text-primary">Kualitas dijamin yang terbaik</span> dan bergaransi.</p>
                            </div>
                            <div className="d-flex gap-3 mb-3">
                                <FontAwesomeIcon size="3x" className="text-primary" icon={faPaperPlane} />
                                <p>Proses <span className="text-primary">pembuatan cepat</span> dan <span className="text-danger">pengiriman gratis</span> ke seluruh Indonesia</p>
                            </div>
                            <div className="d-flex gap-3 mb-3">
                                <FontAwesomeIcon size="3x" className="text-primary" icon={faClover} />
                                <p>Bahan yang digunakan <span className="text-primary">ramah lingkungan</span> dan bebas dari racun / bahan kimia berbahaya.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SectionAbout;
