import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../Navbar/Navbar.module.scss';
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <footer>
            <div className="container px-5">
                <div className="row py-5">
                    <div className="col-lg-4 mb-4 mb-lg-auto">
                        <img className={styles.img_logo} src="https://i.ibb.co/WxC7dRj/image.png" alt="Jago Kemasan" />
                        <p className="my-3">Jagonya bikin kemasan, produsen box kemasan dan paper bag murah untuk kebutuhan produk Anda</p>
                    </div>
                    <div className="col-lg-4 mb-4 mb-lg-auto">
                        <h5 className="fw-bolder mb-4">Kontak Kami</h5>
                        <a className="link-dark text-decoration-none" href="mailto:JagoKemasan.com@gmail.com">
                            <div className="d-flex gap-2 align-items-center mb-3">
                                <FontAwesomeIcon fixedWidth icon={faEnvelope} />
                                JagoKemasan.com@gmail.com
                            </div>
                        </a>
                        <a className="link-dark text-decoration-none" href="https://wa.me/62895402932828?text=Halo, saya ingin order dengan kode promo JAGOBERKAH, tolong dibantu.">
                            <div className="d-flex gap-2 align-items-center mb-3">
                                <FontAwesomeIcon fixedWidth icon={faWhatsapp} />
                                +62895402932828
                            </div>
                        </a>
                        <div className="d-flex gap-2 align-items-start mb-3">
                            <FontAwesomeIcon fixedWidth icon={faMapMarkerAlt} />
                            Jl. Ring Road Utara No.88, Trini, Trihanggo, Kec. Gamping, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55291
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <h5 className="fw-bolder mb-4">Lokasi Kami</h5>
                        <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.294841875992!2d110.33279874403358!3d-7.758521972365772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a587dce0c617b%3A0x4279ae15c48141c5!2sJl.%20Ring%20Road%20Utara%20No.88%2C%20Bedog%2C%20Trihanggo%2C%20Kec.%20Gamping%2C%20Kabupaten%20Sleman%2C%20Daerah%20Istimewa%20Yogyakarta%2055592!5e0!3m2!1sen!2sid!4v1710066414494!5m2!1sen!2sid" width="100%" height="150px" style={{ border: 0, borderRadius: '15px' }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-auto my-auto">
                        <p className="mb-0">
                            &copy;
                            {' '}
                            {year}
                            {' '}
                            Lestary Media Agency. All rights reserved.
                        </p>
                    </div>
                    <div className="col-auto my-auto">
                        <div className="d-flex gap-2">
                            <p className="my-auto">Advertising by</p>
                            <img height={50} width={100} className="my-auto" style={{ objectFit: 'contain' }} src="https://www.lestarymedia.com/assets/img/logo-dark.png" alt="Lestary Media Agency" />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
